@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-slate-900 text-slate-400 font-sans antialiased scroll-smooth selection:bg-amber-500 selection:text-slate-200;
  }

  h3 {
    @apply text-sm font-bold uppercase tracking-widest text-slate-300;
  }
}

#wrapper-home h1 {
  @apply mb-2 text-4xl font-bold tracking-wide text-slate-900 md:text-[44px] md:mb-3 lg:tracking-wider;
}

#wrapper-home h2 {
  @apply flex flex-col text-slate-300 font-semibold md:flex-row md:gap-x-2 md:text-lg lg:flex-col lg:mb-4 lg:tracking-wide lg:leading-6;
}

#wrapper-404 h1 {
  @apply pb-2 text-7xl font-extrabold text-slate-900;
}

#wrapper-404 h2 {
  @apply text-xl text-current font-semibold;
}

a.highlighted {
  @apply font-bold text-slate-300 hover:text-amber-500;
}

.text-outlined {
  text-shadow:
    -1px -1px 0 #e2e8f8,
    1px -1px 0 #e2e8f8,
    -1px 1px 0 #e2e8f8,
    1px 1px 0 #e2e8f8;
}

.section-title {
  @apply sticky top-0 -mx-4 mb-1 pl-4 py-3 z-20 bg-slate-900/75 backdrop-blur-md md:mb-3 lg:pt-8;
}

/* custom list element markers */
/* note that ::marker does not allow for custom margins */
.list-skills li::before {
  content: "\2756";
  @apply mr-1;
}

/* allocate top scroll margin to each job link */
li.job {
  scroll-margin-top: 6rem;
}

#sections-nav li a {
  @apply flex items-center pt-2 pb-3;

  &.active {
    & .nav-indicator {
      @apply w-16 bg-slate-200 text-slate-200;
    }

    & .nav-text {
      @apply text-slate-200;
    }
  }
}

.pill {
  @apply flex items-center rounded-full px-3 py-1 text-sm font-semibold leading-5 bg-slate-500/15 text-amber-500 border border-slate-300/20 md:text-xs;
}

@media screen(lg) {
  #wrapper-home header {
    position: sticky;
    top: 0;
    height: 100%;
    height: 100dvh;
  }
}